<template>
  <div id="TopBar" :class="{ sticky: isScrolled }">
    <!-- <div class="popupLine" @mouseover="showPopup" /> -->
    <Wrap class="xl">
      <MainLogo v-if="isScrolled" :isScrolled="isScrolled" src="../../../assets/nogrid-logo-black.png" />
      <MainLogo v-else :isScrolled="isScrolled" />
      <div
        v-if="screenSizes.includes('xl')"
        id="menu"
        :aria-label="$t('label')"
        :class="{ show: doShowMenu, padded: !hasOfferButton }"
        role="navigation">
        <MenuLink
          v-for="(link, index) in menu"
          :key="index"
          :class="link.linkClass"
          :index="index"
          :link="link" />
        <!-- <Btn class="cta" :to="">
          {{ $t('cta') }}
        </Btn> -->
      </div>
      <div v-else id="mobile-menu-cont">
        <div
          ref="hamburger"
          id="hamburger"
          @click="doShowMenu = !doShowMenu"
          @keypress.enter="doShowMenu = !doShowMenu">
          <Icon :name="doShowMenu ? 'cross' : 'bars'" />
        </div>
        <transition name="slide-side">
          <div v-if="doShowMenu" ref="mobileMenu" id="mobile-menu" role="navigation">
            <div>
              <MenuLink
                v-for="(link, index) in menu"
                :key="link.label"
                :class="{
                  last: index === (menu.length - 1),
                  [link.linkClass]: link.linkClass,
                }"
                :index="index"
                :link="link"
                @click="hideMenu" />
            </div>
          </div>
        </transition>
      </div>
    </Wrap>
  </div>
</template>

<script>
import { actions, getters, mergeLabels } from 'views/utils'
import MainLogo from './MainLogo'
import MenuLink from './MenuLink'
// import { formExperimentId } from '../../../config'

export default {
  components: { MainLogo, MenuLink },
  data() {
    return {
      scrollPosition: 0,
      ctaRoute: 'Contact',
      contactFormHashDefault: '#FooterContact',
      contactFormHashesForSpecificRoutes: {
        SolarPlantsB2b: '#FormContactB2b',
      },
      doShowMenu: false,
      staticMenu: [
        {
          children: [
            { to: 'SolarPlants' },
            { to: 'SolarPlantsB2b' },
            { href: 'https://saulesbendruomene.lt/' },
            // { to: { name: 'AboutUs', hash: '#business-model' } },
          ],
        },
        // { to: 'SolarPlants' },
        // { to: 'Subsidy' },
        { to: 'LandingPageSale' },
        { to: 'Financing' },
        { to: 'Portfolio' },
        { to: 'AboutUs' },
        { to: 'Contact' },
      ],
    }
  },
  computed: {
    hasOfferButton() {
      return this.isScrolled || (this.$route.name !== 'Index')
      // const experiment = this.getExperiment(formExperimentId)
      // if (experiment) {
      //   return experiment.combination === '0'
      // }

      // return true
    },
    currentRouteCtaHash() {
      return this.contactFormHashesForSpecificRoutes[this.$route.name] || this.contactFormHashDefault
    },
    localeMenu() {
      return {
        label: 'Lt',
        hasIgnoredActive: true,
        children: this
          .locales
          .map(({ label, value }) => ({
            label,
            to: {
              name: 'Index',
              params: { locale: this.defaultLocale === value ? undefined : value },
            },
          })),
      }
    },
    menu() {
      const appendLabels = ({ children, ...item }, key) => ({
        ...item,
        children: mergeLabels(children, this.$ta(key)),
      })

      const { screenSizes } = this
      const isTablet = screenSizes.includes('md') && !screenSizes.includes('lg')
      const isDefaultLocale = this.defaultLocale === this.locale

      const staticMenuFull = this.hasOfferButton
        ? [
          ...this.staticMenu,
          { to: { name: this.ctaRoute, hash: this.currentRouteCtaHash }, linkClass: 'Btn cta', hasIgnoredActive: true },
        ]
        : [...this.staticMenu]

      const menu = mergeLabels([
        appendLabels(staticMenuFull[0], 'menu.solarPlantChoices'), ...staticMenuFull.slice(1)], this.$ta('menu.top'))
        .map((item, index, menuArray) => ({
          ...item,
          doShow: isDefaultLocale || (!isTablet || (!index || index === menuArray.length - 1)),
        }))

      return menu.filter(item => typeof item.doShow === 'undefined' || item.doShow)
    },
    isScrolled() {
      return this.scrollPosition > 0
    },
    ...getters('defaultLocale', 'locales', 'screenSizes', 'getExperiment', 'hasPopupShowed', 'appStartedAt'),
  },
  methods: {
    // showPopup() {
    //   const MAX_TIME_TO_SHOW_POPUP_DESKTOP = 60 * 1000
    //   if ((Date.now() - this.appStartedAt < MAX_TIME_TO_SHOW_POPUP_DESKTOP) && !this.hasPopupShowed) this.SET_POPUP_STATUS(true)
    // },
    // closePopup() {
    //   this.SET_POPUP_SHOWED_STATUS(true)
    // },
    checkClickLocation(event) {
      if (!this.$refs.mobileMenu || !this.doShowMenu) return

      if (!['hamburger', 'mobileMenu'].some(ref => this.$refs[ref].contains(event.target))) {
        this.doShowMenu = false
      }
    },
    hideMenu() {
      this.doShowMenu = false
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    ...actions('SET_POPUP_STATUS'),
  },
  watch: {
    '$route.name': {
      immediate: true,
      async handler(routeName) {
        await this.$nextTick()

        this.ctaRoute = document.querySelector(this.currentRouteCtaHash) ? routeName : 'Index'
      },
    },
  },
  async beforeMount() {
    this.$offEvents = await this.ON({
      click: this.checkClickLocation,
    })
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
    window.removeEventListener('scroll', this.updateScroll)
  },
}
</script>

<style lang="scss">
$_burger-width: 2.25rem;

#TopBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7;

  .popupLine {
    background-color: transparent;
    height: 5px;
    // position: absolute;
  }

  > .Wrap {
    border-bottom: 1px solid $white;
    padding: 1rem 0.5rem 0.125rem;
    @include xs {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
    @include xl {
      border: 0;
      padding: 1rem 2rem 0.125rem;
    }
  }

  .MainLogo {
    @include trans();

    img {
      @include trans();
    }
  }

  .cta {
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    @include xl {
      margin-left: 0.65rem;
    }
    @include xxl {
      margin-left: 1.5rem;
    }
  }

  &.sticky {
    background-color: $white;
    border-bottom: 1px solid $border-color;

    .MainLogo {
      padding: 0 0 0.5rem;

      img {
        height: 40px;
        padding: 0;
      }
    }

    #menu {
      min-height: unset;
      padding: 0;
      // padding: 0.8rem 0 0;

      a, .parent-link, .i-angle-down, .i-angle-up {
        color: $grey-darker;
      }

      .cta a {
        color: $white;
      }

      &.padded {
        padding: 0.8rem 0 0;
      }
    }

    #hamburger {
      margin-top: 1rem;
    }
  }
}

#menu {
  color: $white;
  display: none;
  float: right;
  min-height: 5rem;
  padding: 0 0 0.5rem;
  @include xl {
    align-items: center;
    display: flex;
    // padding: 1rem 0;
  }
  @include xxl {
    padding: 0.5rem 0 0;

    // padding: 1.5rem 0;
  }

  a {
    &:hover {
      @include trans;
      text-decoration: underline;
    }
  }

  .cta a {
    &:hover {
      text-decoration: unset;
    }
  }
}

#mobile-menu-cont {
  float: right;
  @include xl {
    display: none;
  }
}

#mobile-menu {
  align-items: center;
  background: $main;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 20%;
  position: fixed;
  top: 0;
  width: 80%;
  z-index: 10;

  .AuthMenu {
    display: block;
  }

  .cta {
    background-color: $white;
    margin-top: 1.5rem;

    a {
      color: $main;
    }
  }
}

#hamburger {
  background: $white;
  border-radius: $radius;
  color: $black-ter;
  cursor: pointer;
  float: right;
  font-size: 2rem;
  height: $_burger-width;
  line-height: $_burger-width;
  margin-top: 1rem;
  position: relative;
  text-align: center;
  width: $_burger-width;
  z-index: 11;
  @include maxSm {
    display: inline-block;
  }
  @include md {
    margin-top: 1rem;
  }
}

// @animation
.slide-side-enter-active,
.slide-side-leave-active {
  @include trans;
  opacity: 1;
  transform: translateX(0);
}

.slide-side-enter,
.slide-side-leave-to {
  opacity: 0;
  transform: translateX(300px);
}
</style>

<i18n>
{
  "lt": {
    "label": "Meniu",
    "menu": {
      "solarPlantChoices": [
        "Namams",
        "Verslui",
        "Saulės parkai"
      ],
      "top": [
        "Saulės elektrinės",
        "Geriausios kainos garantija",
        "Finansavimas",
        "Įgyvendinti projektai",
        "Apie mus",
        "Kontaktai",
        "Gaukite pasiūlymą"

      ]
    }
  }
}
</i18n>
